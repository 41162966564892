import React from "react"
import Layout from "../components/layout"
import Head from "../components/head"
import { Link, graphql } from "gatsby"
import { getFirstParaExcerpt } from "../utils/blogListHelpers"
import blogListStyles from "./blog-list.module.scss"
import ReadingTime from "../components/readingTime"

export const query = graphql`
  query($limit: Int!, $skip: Int!) {
    allContentfulBlogPost(
      sort: { fields: publishedDate, order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          title
          slug
          publishedDate(formatString: "MMMM Do, YYYY")
          body {
            content {
              content {
                value
              }
            }
          }
        }
      }
    }
  }
`

const BlogList = props => {
  const {
    pageContext: { currentPage, numPages, rootPagePath, otherPagesPath, postTextLengths },
    data
  } = props
  const isFirst = currentPage === 1
  const isLast = currentPage === numPages
  const prevPage =
    currentPage === 2 ? rootPagePath : `${otherPagesPath}${currentPage - 1}`
  const nextPage = `${otherPagesPath}${currentPage + 1}`

  const CMSEdgesData = data.allContentfulBlogPost.edges
  const CMSPostItems = CMSEdgesData.map((edge, index) => {
    const { title, slug, publishedDate, body } = edge.node
    return (
      <li className={blogListStyles.post} key={slug}>
        <Link to={`${rootPagePath}${slug}/`}>
          <div className={blogListStyles.titleAndReadingTimeContainer}>
            <h3>{title}</h3>
            <ReadingTime className={blogListStyles.readingTime} numberOfWords={postTextLengths[index]} />
          </div>
          <p>{publishedDate}</p>
          <p>{getFirstParaExcerpt(body)}</p>
        </Link>
      </li>
    )
  })

  return (
    <Layout isHome={false}>
      <Head
        title="Blog"
        description="Blog articles about Cognitive Behavioural Therapy (CBT), radical politics, and the intersection of mental health with power, privilege, and responsibility."
      />
      <h2>Blog</h2>
      <ol className={blogListStyles.posts}>{CMSPostItems}</ol>
      <div className={blogListStyles.bottomPageNavContainer}>
        <div>
          {!isFirst && (
            <Link to={prevPage} rel="prev">
              ← Previous Page
            </Link>
          )}
        </div>
        <div>
          {!isLast && (
            <Link to={nextPage} rel="next">
              Next Page →
            </Link>
          )}
        </div>
      </div>
    </Layout>
  )
}
export default BlogList
