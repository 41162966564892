const EXCERPT_CHAR_LIMIT = 140
const ZERO = 0
const READING_SPEED_WPM = 250

export const getFirstParaExcerpt = body => {
  const firstPara =
    body.content.filter(contentObj => {
      if (contentObj.content[ZERO]?.value?.length >= EXCERPT_CHAR_LIMIT) {
        return true
      }
      return false
    })[ZERO]?.content[ZERO].value ?? []

  if (firstPara.length < EXCERPT_CHAR_LIMIT) return null

  const paraToSanitize = firstPara.slice(ZERO, EXCERPT_CHAR_LIMIT).trim()
  const lastCharIndex = paraToSanitize.length - 1

  return paraToSanitize[lastCharIndex] === "."
    ? `${paraToSanitize.slice(ZERO, [lastCharIndex])}...`
    : `${paraToSanitize}...`
}

const calculateReadingTime = numberOfWords => Math.round(numberOfWords / READING_SPEED_WPM)

export const getFormattedReadingTime = numberOfWords => numberOfWords ? `${calculateReadingTime(numberOfWords)} min read` : null